import React, { Component } from 'react';
import {
  MainContainer,
  BarGraphDesign,
  DisplayGraphData,
  LineBackground,
  Border
} from './styles'
import { isNull, isUndefined, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Waiting from '../../Waiting';
import { ImageUrl } from '../../../utils/constants';
import CSVList from "../../AttendeesModal/CSVList";
import HoverButton from '../../common/HoverButton';
import TopSection from '../../common/Analytics/TopSection';
import LazyImage from '../../common/LazyImage/LazyImage';
import { DownloadIconNew } from '../../../utils/icons';
import { getRecognitionActivityDetails, getRecognitionForAllDepartments, getRecognitionForAllLocations, setSelectedYear } from '../../../redux/actions';
// import { FourCardWrapper, NewImgContainerV2, RowV1, RowV2, TotalCountNewV2 } from '../styles';;
import { commaSeperator } from '../../../utils/methods';
import {TopCardEngagement, TopChallengesWrapper} from '../../CompanyEvents/styles';

class CompanyAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentsIndividualSum: [],
      departmentsTotalSum: 0,
      topDepartmentsDetails: [],
      locationsIndividualSum: [],
      locationsTotalSum: 0,
      topLocationsDetails: [],
      companyId: props.companyInfo['id'],
      open: false,
      openLocation: false,
      buttonIndex: null,
      close: true,
      printDepartmentCSV: false,
      updatedDepartmentCSV: [],
      topDepartmenttype: 0,
      removeCSV: 0,
      displayPercentage: false,
      lineNumber: null,
      printGraphCSV: false,
      updatedGraphCSV: [],
      locationArrayData: [{ label: "Location", key: "LOCATION" }],
      departmentArrayData: [{ label: 'Department', key: 'DEPARTMENT' }],
      isYearChangedForDepartment : false,
      isYearChangedForLocation : false
    }
  }

  componentDidMount() {
    const { getRecognitionDepartmentDetails, getRecognitionLocationDetails } = this.props;
    let locationTotalSum = 0;
    let locationIndividualSum = [];
    let topLocationName = [];
    let depTotalSum = 0;
    let depIndividualSum = [];
    let topDepartmentsName = [];
    if (!isEmpty(getRecognitionDepartmentDetails)) {
      getRecognitionDepartmentDetails.map((data) => {
        depTotalSum = depTotalSum + data['received_recognition'] + data['given_recognition'];
        depIndividualSum.push(data['received_recognition'] + data['given_recognition']);
        topDepartmentsName.push(data['department']);
      });
    }
    if (!isEmpty(getRecognitionLocationDetails)) {
      getRecognitionLocationDetails.map((data) => {
        locationTotalSum = locationTotalSum + data['received_recognition'] + data['given_recognition'];
        locationIndividualSum.push(data['received_recognition'] + data['given_recognition']);
        topLocationName.push(data['location']);
      })
    }
    this.setState({ departmentsIndividualSum: depIndividualSum, departmentsTotalSum: depTotalSum, topDepartmentsDetails: topDepartmentsName, locationsIndividualSum: locationIndividualSum, locationsTotalSum: locationTotalSum, topLocationsDetails: topLocationName })
  }

  updateDropdown = (year,id) => {
    const {
      setSelectedYear,
      getRecognitionForAllDepartments,
      getRecognitionForAllLocations,
    } = this.props;
    const { companyId } = this.state;
    setSelectedYear(year);
    getRecognitionForAllDepartments(companyId, year);
    getRecognitionForAllLocations(companyId, year);
    id === "department" ? this.setState({isYearChangedForDepartment : true, isYearChangedForLocation: false})  : 
      id === "location" ? this.setState({isYearChangedForLocation : true, isYearChangedForDepartment: false}) : null
  };


  printGraphData = (bool) => {
    this.printGraphAction(bool);
  }

  printGraphAction = (bool) => {
    const { recognitionDetails } = this.props;
    let updatedGraphCSV = recognitionDetails && recognitionDetails.length > 0 &&
      recognitionDetails.map((data) => {
        let obj = {};
        obj['NAME'] = data['name'];
        obj['COUNT'] = data['count'];
        obj['PERCENTAGE'] = data['percentage'];
        return obj;
      });
    this.setState({ printGraphCSV: bool, updatedGraphCSV: updatedGraphCSV })
  };


  getBarGraphDesign = () => {
    const { recognitionDetails } = this.props;

    const handleMouseEnter = (index) => {
      this.setState({ lineNumber: index })
      this.setState({ displayPercentage: true })
    }

    const handleMouseLeave = (index) => {
      this.setState({ lineNumber: index })
      this.setState({ displayPercentage: false })
    }

    return (
      <div style={{marginRight:'65px', width:"100%", height:"100%"}}>
        {
          recognitionDetails.map((item, index) => (
            (index + 1 <= recognitionDetails.length) && <DisplayGraphData key={index} style={{height:'50px'}}>
              <h1>{item.name}</h1>
              <LazyImage src={`${ImageUrl}/${item.icon}`} />
              <div className='y-axis' />
              {item.name ?
                <LineBackground linecolor={item.color} percentWidth={item.percentage} >
                  <div className='line'
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                  />
                  {/* {this.state.displayPercentage && index == this.state.lineNumber && <span className='showPercent'>{((item.count * 20) / 20).toFixed(1)}</span>} */}
                  {this.state.displayPercentage && index == this.state.lineNumber && <div className='show-box'><span className='title'>Total {item.name} </span><div><span className='title'>Received :</span> <span className='value'> {((item.count * 20) / 20).toFixed(0)}</span></div><span className='title'>Average :</span><span className='value'> {((item.percentage * 20) / 20).toFixed(0)}%</span></div>}
                </LineBackground>
                : null
              }
            </DisplayGraphData>
          ))
        }
      </div>
    )
  }

  renderBarGraph = () => (
    <BarGraphDesign>
      <div className='bar-graph-container'>
        <div className='title-container'>
          <p className='heading'>Your {this.props.selectedYearInfo} Company Values</p>
          <div style={{marginRight:'15px'}}>
            <HoverButton width={"40px"} height={"40px"} title={"Download"} fillOpacity={1} disabled={false} backgroundColor={"#f0f6f8"} backgroundColorActive={"#005c87"} activeColor={"#619ab5"} inActiveColor={"white"} svgPath={<DownloadIconNew/>} 
              onClick={()=>this.printGraphData(true)}/>
          </div>
        </div>
        <hr className='hr-line' style={{marginTop:'15px', border: "1px solid rgba(0, 92, 135, 0.30)"}}/>
        <div style={{display:"flex", width:"100%"}}>
          {this.getBarGraphDesign()}
          <Border left="859px"></Border>
          <Border left="660px"></Border>
          <Border left="462px"></Border>
          <Border left="265px"></Border>
          <Border left="65px"></Border>
        </div>
        <div className='bottom-wrapper'>
          <div className='hrline-wrapper'>
            <div className='x-axis-horizontal' />
          </div>
          <div className='graphs-x-axis'>
            <div className='wrapperXaxis'>
              <div className='x-points'>0</div>
              <div className='x-points'>20</div>
              <div className='x-points'>40</div>
              <div className='x-points'>60</div>
              <div className='x-points'>80</div>
              <div className='x-points'>100</div>
            </div>
          </div>
          <p style={{fonrSize:"16px",lineHeight:'20px',textAlign:"center", marginTop:'25px', color:'#669db7'}}>Average Recognition Received  --{">"}</p>
        </div>
        <div className='bottom-text' />
      </div>
    </BarGraphDesign>
  )

  fallBack = (e) => {
    e.target.src = "/public/images/default.png";
  };

  showRecognitionDetails = () => {
    const { getRecognitionActivityDetails } = this.props;
    const EngagementEmployeeData = [
      {
        title: "Total Social Post",
        value: "1",
        img: "/images/SocialBox.svg",
        text: "posted",
        color: "rgba(133, 192, 234, 1)",
        total: getRecognitionActivityDetails.social_posts,
        imageBackground: "rgba(133, 192, 234, 0.15)",
        subText: "Posts",
        info: "",
        average: getRecognitionActivityDetails?.user_post_posted_percentage + " % User" + (getRecognitionActivityDetails?.user_post_posted_percentage > 1 ? "s" : ""),
      },
      {
        title: "Recognition Received",
        titleNew: "",
        value: "2",
        img: "/images/TotalRecognitionimg.svg",
        text: "received recognition",
        color: "rgba(220, 148, 181, 1)",
        total: getRecognitionActivityDetails?.received_recognition,
        average: getRecognitionActivityDetails?.received_recognition_percentage + " % User" + (getRecognitionActivityDetails?.received_recognition_percentage > 1 ? "s" : ""),
        imageBackground: "rgba(220, 148, 181, 0.15)",
        subText: "People Received",
        info: "",
      },
      {
        title: "Recognition Given",
        value: "3",
        img: "/images/PeerRecognitionimg.svg",
        text: "gave recognition",
        color: "rgba(118, 171, 120, 1)",
        total: getRecognitionActivityDetails?.gave_recognition,
        average:
        getRecognitionActivityDetails?.gave_recognition_percentage + " % User" + (getRecognitionActivityDetails?.gave_recognition_percentage > 1 ? "s" : ""),
        imageBackground: "rgba(118, 171, 120, 0.15)",
        subText: "People Gave",
        info: "",
      },
      {
        title: "Exemplifies Company",
        titleNew: "Value",
        value: "4",
        img: "/images/Exempifiesimg.svg",
        text: "received value",
        color: "rgba(175, 135, 193, 1)",
        total: getRecognitionActivityDetails?.exemplifies,
        average: getRecognitionActivityDetails?.exemplifies_percentage + " % User" + (getRecognitionActivityDetails?.exemplifies_percentage > 1 ? "s" : ""),
        imageBackground: "rgba(175, 135, 193, 0.15)",
        subText: "People Exemplified",
        info: "",
      },
    ];
    return (
      EngagementEmployeeData.map((details, index) => (
        <TopCardEngagement
          key={index}
          background={details.imageBackground}
          color={details.color}
          style={{ padding: "12px" }}
        >
          <div className="first-child">
            <div className="image">
              <img
                src={ImageUrl + details.img}
                alt={"engagement-employee"}
                onError={this.fallBack}
              />
            </div>
            <div className="title">
              {details.title}
              <br />
              {details.titleNew}
            </div>
          </div>
          <div className="challengeData">
            <span className="value">{commaSeperator(details?.total)}</span>
            <span className="valueText">{details.subText}</span>
          </div>
          <div
            className="borderCard"
            style={{ width: "100%", margin: "20px 0px" }}
          />
          <div className="last-child">
            <span className="totalParticipant">{details.average}</span>
            <span className="participateText">{details.text}</span>
          </div>
        </TopCardEngagement>
      ))
    );
  };

  render() {
    const { getRecognitionDepartmentDetails, getRecognitionLocationDetails, getRecognitionForAllDepartmentsDetails, getRecognitionForAllLocationsDetails, isLoading, recognitionDetails, selectedYearInfo, yearList,getRecognitionActivityDetails } = this.props;
    let finalRecognitionForAllDepartmentsDetails=getRecognitionForAllDepartmentsDetails;
    if(finalRecognitionForAllDepartmentsDetails && finalRecognitionForAllDepartmentsDetails.length > 0){
      for (let i = 0; i < finalRecognitionForAllDepartmentsDetails.length; i++) {
        let total = 0;
        for (let j = 0; j < finalRecognitionForAllDepartmentsDetails[i].data.length; j++) {
          total = total + finalRecognitionForAllDepartmentsDetails[i].data[j].count;
        }
        let isPresent = finalRecognitionForAllDepartmentsDetails[i].data.findIndex((item)=> item.recognition === "Total Recognition");
        if(isPresent < 0){
          finalRecognitionForAllDepartmentsDetails[i].data.unshift({
            recognition: "Total Recognition",
            icon: "social-recognition/thanks_icon.png",
            key:"total",
            count: total})
        }
      }
    }

    let finalRecognitionForAllLocationsDetails=getRecognitionForAllLocationsDetails;
    if(finalRecognitionForAllLocationsDetails && finalRecognitionForAllLocationsDetails.length > 0){
      for (let i = 0; i < finalRecognitionForAllLocationsDetails.length; i++) {
        let total = 0;
        for (let j = 0; j < finalRecognitionForAllLocationsDetails[i].data.length; j++) {
          total = total + finalRecognitionForAllLocationsDetails[i].data[j].count;
        }
        let isPresent = finalRecognitionForAllLocationsDetails[i].data.findIndex((item)=> item.recognition === "Total Recognition");
        if(isPresent < 0){
          finalRecognitionForAllLocationsDetails[i].data.unshift({
            recognition: "Total Recognition",
            icon: "social-recognition/thanks_icon.png",
            key:"total",
            count: total})
        }
      }
    }


    const {  printGraphCSV, updatedGraphCSV } = this.state;
    if (!isNull(getRecognitionForAllLocationsDetails) && getRecognitionForAllLocationsDetails.length && getRecognitionForAllLocationsDetails) {
      getRecognitionForAllLocationsDetails[0];
    }
    if (isNull(getRecognitionDepartmentDetails) || isUndefined(getRecognitionDepartmentDetails) || isNull(getRecognitionLocationDetails) || isUndefined(getRecognitionLocationDetails) || isNull(getRecognitionForAllDepartmentsDetails) || isUndefined(getRecognitionForAllDepartmentsDetails) || isNull(getRecognitionForAllLocationsDetails) || isUndefined(getRecognitionForAllLocationsDetails) || isUndefined(getRecognitionActivityDetails) || isNull(getRecognitionActivityDetails) || isLoading) {
      return <Waiting />
    }
  

    const fileTitleGraph = "BarGraphData"
    const csvHeadersGraph =
      [
        { label: "NAME", key: "NAME" },
        { label: "COUNT", key: "COUNT" },
        { label: "PERCENTAGE", key: "PERCENTAGE" },
      ]

    return (
      <MainContainer>
        <TopChallengesWrapper margin="0 0 40px 0">
          {this.showRecognitionDetails(recognitionDetails)}
        </TopChallengesWrapper>
        {this.renderBarGraph(recognitionDetails)}
        {printGraphCSV && <CSVList Title={fileTitleGraph} removeCSVData={this.printGraphAction} headers={csvHeadersGraph} data={updatedGraphCSV} />}
        <TopSection selectedYearInfo={selectedYearInfo} yearDataList={yearList} onYearSelect={this.updateDropdown} isYearChanged={this.state.isYearChangedForDepartment} isYearDropdown={true} id={'department'} title={"Department Peer Recognition"} colors={['#85C0EA','#F1C977','#F4AAA9']} topData={getRecognitionDepartmentDetails} keys={[{key:"received_recognition",text:"Recognition Received"},{key:"given_recognition",text:"Recognition Given"}]} topSectionKey={"department"} seeAllTitle={"See all Breakdown"} tableData={finalRecognitionForAllDepartmentsDetails} tableTitle={"All Departments"} tableColumnTitle={"Department"} tableColumnKey={"department"} tableRowKey={"recognition"}/>
        <TopSection selectedYearInfo={selectedYearInfo} yearDataList={yearList} onYearSelect={this.updateDropdown} isYearChanged={this.state.isYearChangedForLocation} isYearDropdown={true} id={'location'} title={"Location Peer Recognition"} colors={['#85C0EA','#F1C977','#F4AAA9']} topData={getRecognitionLocationDetails} keys={[{key:"received_recognition",text:"Recognition Received"},{key:"given_recognition",text:"Recognition Given"}]} topSectionKey={"location"} seeAllTitle={"See all Breakdown"} tableData={finalRecognitionForAllLocationsDetails} tableTitle={"All Locations"} tableColumnTitle={"Location"} tableColumnKey={"location"} tableRowKey={"recognition"}/>
      </MainContainer>
    );
  }
}

CompanyAnalytics.propTypes = {
  companyInfo: PropTypes.object,
  getRecognitionDepartmentDetails: PropTypes.array,
  getRecognitionLocationDetails: PropTypes.array,
  getRecognitionForAllDepartmentsDetails: PropTypes.array,
  getRecognitionForAllLocationsDetails: PropTypes.array,
  isLoading: PropTypes.bool,
  recognitionDetails: PropTypes.array,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  setSelectedYear:PropTypes.number,
  getRecognitionForAllDepartments:PropTypes.func,
  getRecognitionForAllLocations:PropTypes.func,
  getRecognitionActivityDetails : PropTypes.array
};

const mapStateToProps = (state) => ({
  getRecognitionDepartmentDetails: state.companyDashboard.getRecognitionDepartmentDetails,
  getRecognitionLocationDetails: state.companyDashboard.getRecognitionLocationDetails,
  isLoading: state.companyDashboard.isLoading,
  getRecognitionForAllDepartmentsDetails:
  state.companyDashboard.getRecognitionForAllDepartmentsDetails,
  getRecognitionForAllLocationsDetails:
  state.companyDashboard.getRecognitionForAllLocationsDetails,
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  getRecognitionActivityDetails:
    state.companyDashboard.getRecognitionActivityDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
  getRecognitionForAllDepartments: (id, year) =>
    dispatch(getRecognitionForAllDepartments(id, year)),
  getRecognitionForAllLocations: (id, year) =>
    dispatch(getRecognitionForAllLocations(id, year)),
  fetchRecognitionActivityDetails: (id, year) =>
    dispatch(getRecognitionActivityDetails(id, year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAnalytics);