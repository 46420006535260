import React, {Component} from 'react';
import PropTypes from "prop-types";
import {PieChartContainer} from "./styles";
import DonutChart from "../../DonutChartV3";
import SolidGauge from '../../DonutChartV2/solidGauge'
export default class TopDepartment extends Component{
  constructor(){
    super();
    this.state = {
      options: ["2019","2018","2017"],
      selectedIndex: 0,
      selectedValue: 2019
    }
  }

  renderDonutChart = (chartData, container) =>{
    const total = chartData.reduce((acc, item) => (acc + item.y
    ), 0);
    return total  === 0 ? <div style={{marginTop : '36px', width: '270px', height: '100%'}}><SolidGauge container={container} legendWidth={100} y={0}/></div>: <DonutChart data={chartData} container={container} legendWidth={62}/>
  };

  render() {
    const {container, chartData} = this.props;
    return(
      <PieChartContainer>
        {this.renderDonutChart(chartData, container)}
      </PieChartContainer>
    )
  }
}

TopDepartment.propTypes = {
  chartData: PropTypes.array,
  container: PropTypes.string
};

